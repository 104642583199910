<template>
  <v-container fluid>
    <PageToolbar :title="$tc('global.versoes', 2)" icon="mdi-view-list" dark />
    <v-row class="px-4 mt-4">
      <v-col class="">
        <v-row class="h4">{{ empresa.nome }}</v-row>
        <v-row class="sub1 my-lg text-black-3">
          <span class="pr-2">Versão atual: </span>
          <span class="body-1 text-black"> {{ app_version }}</span>
        </v-row>
        <v-row class="subtitle-1 my-md text-black-3">Histórico de atualizações</v-row>
        <v-card class="mt-8" >
        <v-data-table
            v-if="versao.length > 0"
            :headers="columns"
            :items="versao"
            :items-per-page="10"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td class="text-right body-1">{{ item.versao }}</td>
                    <td>
                        <div v-for="atualizacao in item.atualizacoes" :key="atualizacao" class="flex my-auto pt-sm">
                            <div v-if="atualizacao.mudancas.length > 0">
                                <v-chip class="black-4 body-2 my-2" style="height: 20px; ">
                                    {{ atualizacao.tipo }}
                                </v-chip>
                                <div v-for="mudanca in atualizacao.mudancas" :key="mudanca">
                                    <div class="subtitle-2 mb-1">- {{ mudanca.descricao }}</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="body-1">{{ item.data }}</td>
                </tr>
            </template>
        </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import versoes  from "./versoes.js";
import { version } from "../../package";

export default {
  name: "VersoesSistema",

  data() {
    return {
      app_version: version,
      versao: versoes,
      columns: [
        {
          text: "Versão do sistema",
          value: "versao",
          align: "right",
          style: "width: 5%;",
        },
        {
          text: "Atualizações",
          value: "atualizacoes",
          align: "left",
          style: "width: 75%;",
        },
        {
          text: "Data da atualização",
          value: "data",
          align: "left",
          style: "width: 10%",
        },
      ],
    };
  },
  methods: {
  },
  computed: {
    ...mapState("Usuario", {
      empresa: (state) => state.empresa,
    }),
  },

};
</script>

<style lang="scss" scoped>
/* Your styles here */
</style>

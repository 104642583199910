const versoes = [
  {
    versao: "0.1.62",
    data: "05/04/2024",
    atualizacoes: [
      {
        tipo: "Funcionalidades",
        mudancas: [
          {
            descricao:
              "Adicionada verificação completa para bloquear a criação de cheques duplicados.",
          },
          {
            descricao:
              "Criada a aba de histórico do cliente, trazendo vendas, ordens de serviço e devoluções.",
          },
          {
            descricao:
              "Adicionada edição de produtos na ordem de serviço independente do status.",
          },
        ],
      },
      {
        tipo: "Melhorias",
        mudancas: [
          {
            descricao: "Adicionado novos campos nas tabelas de cheques nas contas a pagar.",
          },
          {
            descricao:
              "Ajustes em relatórios diversos, adicionado novos campos.",
          },
          {
            descricao:
              "Ajustes na página de cheques.",
          },
          {
            descricao:
              "Estilização dos timbrados vencidos.",
          },
        ],
      },
      {
        tipo: "Correções",
        mudancas: [
          { descricao: "Correção dos valores apresentados nos recibos." },
          {
            descricao:
              "Ajuste na impressão de nota de crédito na devolução de venda.",
          },
          { descricao: "Ajuste no desconto da fatura e nota de crédito." },
        ],
      },
    ],
  },
  {
    versao: "0.1.61",
    data: "26/02/2024",
    atualizacoes: [
      {
        tipo: "Melhorias",
        mudancas: [
          {
            descricao:
              "Criando funcionamento correto do adiantamento de valores para fornecedores.",
          },
          {
            descricao:
              "Permitindo salvar mão de obra sem valor na Ordem de Serviço.",
          },
        ],
      },
      {
        tipo: "Correções",
        mudancas: [
          { descricao: "Não permitir registrar dois cheques com mesmo número" },
          { descricao: "Correção de filtro de recibos." },
          { descricao: "Corrigindo cadastro duplicado" },
          {
            descricao:
              "Corrigido erro nas ordens de serviço de piscina, para exibir corretamente os produtos dos Combos.",
          },
        ],
      },
    ],
  },
  {
    versao: "0.1.60",
    data: "01/02/2024",
    atualizacoes: [
      {
        tipo: "Funcionalidades",
        mudancas: [{ descricao: "Adicionado menu de versões." }],
      },
      {
        tipo: "Melhorias",
        mudancas: [
          {
            descricao:
              "Atualizando PDF de produtos de ordem de serviço Avulsa.",
          },
          {
            descricao:
              "Adicionado o status -1 as contas a receber quando a fatura referente é cancelada. ",
          },
          {
            descricao: "Criado filtro para exibir contas a receber canceladas.",
          },
          {
            descricao: "Alterada forma de trabalho na venda de piscinas.",
          },
        ],
      },
      {
        tipo: "Correções",
        mudancas: [
          { descricao: "Corrigindo numeração de futura fora de ordem." },
          {
            descricao:
              "Sistema atualizado para gravar o endereço corretamente a partir de um orçamento.",
          },
          {
            descricao:
              "Corrigindo erro ao tentar cancelar OS manual através do Kanban.",
          },
        ],
      },
    ],
  },
];

export default versoes;
